import React, { useEffect } from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import * as classes from '../../custom-styles/Home.module.css'
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { device } from '../../utils/breakpoints';
import { Link } from 'gatsby';
import HomeLogo from '../../images/HomeLogo.svg';

const HeaderContent = styled.div`
    padding: 20px 20px !important;
    margin:0;
    padding: 12px 0;
        & h1{
    font-size: 40px;
    font-weight: 550;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 40px;
        font-weight: 550;
        }
}
        & p{    
    font-size: 26px;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 18px;
        font-weight: 400;
    }
}
`;
const FreetrialButton = styled.div`
    display:flex;
    align-items:center;
    padding:12px 0;
`;


export default function HomePage() {

  return (
    <Container className={`${classes.py50}`}>
    <Row>
        <Col lg={6}>
            <HeaderContent>
                <Typography variant="h3" gutterBottom><strong>Securely manage your business applications privately</strong></Typography>
                <Typography variant="h5" gutterBottom>Generative AI . Business Intelligence . Private LLMs . CRMs . Productivity Management . Enterprise Data Management . ERPs</Typography>
                <FreetrialButton>
                    <Link to={"/console/signup"} className={`${classes.freetrialButton}`}>Sign In</Link>
                </FreetrialButton>
            </HeaderContent>
        </Col>
        <Col className={`${classes.backgroundimagescover}`} lg={6}>
            <HomeLogo />
        </Col>
    </Row>
</Container>
  )
}
